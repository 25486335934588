import { Store } from "pullstate";
import { ContactData } from "../global";

export interface IBusinessCardStore {
  currentContact: ContactData;
}

const BusinessCardStore = new Store<IBusinessCardStore>({
  currentContact: {
    name: "",
    surname: "",
    email: "",
    phone: "",
    workmail: "",
    role: "",
    linkedin: "",
    facebook: "",
    instagram: "",
    whatsapp: 0,
    company: "",
    website: "",
    uuid: "",
    mode: "",
    imageurl: "",
    logourl: "",
    custom_link: "",
    link1: "",
    label_link1: "",
    link2: "",
    label_link2: "",
    twitter: "",
    youtube: "",
    tiktok: "",
    prefix: "",
    file1url: "",
    file2url: "",
    file3url: "",
    web_interface_name: "",
    file1label: "",
    file2label: "",
    file3label: "",
    workprefix: "",
    workphone: "",
    ismanaged: 0,
  },
});

export default BusinessCardStore;

export const setCurrentContact = (contact: ContactData) => {
  BusinessCardStore.update((s) => {
    s.currentContact = contact;
  });
};
